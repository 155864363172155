export const sideBarLinks = [
	'vtt-electrique',
	'trottinette',
	'accrobranche',
	'via',
	'canyoning',
	'tir-a-larc',
	'cerf-volant',
	'randonnee',
	'sport-deau',
	'peche',
	'disc-golf',
	'trampoline',
	'vtt',
];
