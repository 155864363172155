import lilianImg from '../asset/img/profile.png'
import aloaImg from '../asset/img/aloa_logo.png'
import julienImg from '../asset/img/julien_rispal.jpg'

export const data = {
	vtt: {
		description: {
			title: 'Vtt de descente',
			text: ['Venez découvrir le VTT de descente dans les gorges de la Truyère sur les pistes de la Cadène.', 'Plusieurs pistes disponibles en fonction du niveau, du débutant à l’expert (vertes, bleues, rouges, noires). En libre accès avec matériel de sécurité obligatoire.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Libre accès'],
			title2: 'Ouverture',
			subtitle2: ['Toute l’année'],
			title3: 'Tarifs',
			subtitle3: ['Gratuit']
		},
		contacts: {
			phone: '06 40 45 48 95',
			photo: julienImg,
			diplome: 'Président Association VTT de l’Argence',
			name: 'Julien Rispal',
			email: 'julien.rispal01@gmail.com',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Vtt de descente',
			description: 'Venez découvrir le VTT de descente dans les gorges de la Truyère sur pistes de la Cadène.'
		}
	},
	via: {
		description: {
			title: 'La Via Ferrata du Bois des Baltuergues',
			text: ['A mi-chemin entre la randonnée et l’escalade, partez à la conquête d’un site exceptionnel qui demande agilité et concentration.', 'La Via Ferrata du Bois des Baltuergues vous offre 2 parcours pour découvrir les Gorges de la Truyère comme vous ne les avez jamais vues.', 'Le frisson vous gagnera sûrement quand vous atteindrez les passerelles et autres ponts de singe qui vous offriront des sensations uniques quand, suspendus au-dessus du vide, vous devrez faire preuve d’un peu de courage pour rejoindre l’autre paroi. Après une heure de parcours, vous pourrez choisir entre le parcours « sportif » qui descend plus profond dans les gorges de la Truyère ou le parcours « famille » où vous amorcerez votre remontée.'],
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Encadrement', 'Location de matériel'],
			title2: 'Ouverture',
			subtitle2: ['Site en libre accès toute l’année', 'Encadrement sur réservation toute l’année', 'Location de matériel sur réservation toute l’année'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 10€']
		},
		contacts: {
			phone: '07 52 03 66 48',
			photo: aloaImg,
			diplome: 'Educateur sportif BE escalade',
			name: 'Serge Laborie',
			email: 'aloanature@gmail.com',
			adress: ['Route du pont de mels', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Via ferrata',
			description: 'La Via Ferrata du Bois des Baltuergues vous offre 2 parcours pour découvrir les Gorges de la Truyère.'
		}
	},
	'cerf-volant': {
		description: {
			title: 'Cerf volant',
			text: ['Le cerf-volant est une activité de pleine nature, alliant adresse, précision et technique. Découvrez le cerf-volant autrement en jouant avec les caprices du vent.', 'Différentes tailles de voiles (ailes de traction de 3,5 et 4,5 m) pour s\'adapter aux niveaux, à l\'âge des participants ainsi qu\'à la force du vent. Procure une sensation unique.', 'Apprenez à diriger votre cerf-volant au Buron des Boules, un site majestueux au cœur de l’Aubrac.'],
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Initiation', 'Encadrement', 'Cerf-volant fourni'],
			title2: 'Ouverture',
			subtitle2: ['Toute l\'année sur réservation'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 5€']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Cerf-volant',
			description: 'Le cerf-volant est une activité de pleine nature, alliant adresse, précision et technique.'
		}
	},
	trottinette: {
		description: {
			title: 'Trottinette tout-terrain',
			text: ['Venez vivre la TROT\' ARGENCES EXPERIENCE ! Avec des trottinettes tout terrain électriques accessibles à tous, partez à l’assaut de l’Aubrac de manière insolite en itinérance, accompagnés d’un moniteur.', 'Pour les débutants, venez tester votre agilité autour du plan d’eau de Sainte Geneviève sur Argence.', 'Plusieurs parcours disponibles l’été sur réservation (distance, niveau, durée)', '• Parcours famille - 1h30', '• Parcours aventure - 2h - 2h30', '• Parcours sportif - 2h30', 'Bon cadeau anniversaire, enterrement de vie de garçon / jeune fille, entreprises']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Encadrement', 'Location de matériel'],
			title2: 'Ouverture',
			subtitle2: ['Encadrement sur réservation toute l’année', 'Location de matériel sur réservation toute l’année'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 25€']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Trottinette tout-terrain',
			description: 'Venez vivre la TROT\' ARGENCES EXPERIENCE !'
		}
	},
	randonnee: {
		description: {
			title: 'Randonnées / trails',
			text: ['Vous aimez courir, marcher ? Argences en Aubrac est votre destination !', 'De par son relief et sa variété de paysage, le territoire de l’Aubrac est une région privilégiée pour la pratique de la randonnée pédestre : de la balade familiale sur route goudronnée, aux sentiers sauvages du plateau de l’Aubrac ou des gorges de la Truyère, laissez-vous émerveiller par la beauté des paysages.', 'Nous vous proposons de nombreux chemins balisés sous forme de boucle. D’une heure jusqu’à quatre heures de marche, il y en a pour tous les goûts.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Fiches randonnées / trails', 'Encadrement'],
			title2: 'Ouverture',
			subtitle2: ['Parcours en libre accès'],
			title3: 'Tarifs',
			subtitle3: ['Gratuit', 'Fiches à partir de 1€']
		},
		contacts: {
			phone: '05 65 66 19 75',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Randonnée',
			description: 'Vous aimez marcher ? Argences en Aubrac est votre destination !'
		}
	},
	'tir-a-larc': {
		description: {
			title: 'Tir à l\'arc',
			text: ['Venez tester votre agilité au tir à l\'arc, sport à part entière et discipline ancestrale, qui allie capacités physiques et morales pour en faire un sport complet.', 'Tir à l\'arc pour les enfants à partir de 8 ans et adultes.Réservation obligatoire.En extérieur ou intérieur suivant le temps.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Initiation', 'Encadrement'],
			title2: 'Ouverture',
			subtitle2: ['Toute l’année sur réservation', 'L’été en initiation'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 15€']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Tir à l\'arc',
			description: 'Venez tester votre agilité au tir à l\'arc, sport à part entière et discipline ancestrale !'
		}
	},
	accrobranche: {
		description: {
			title: 'Accrobranche',
			text: ['Prenez de la hauteur dans les arbres, dans un cadre sauvage et préservé où vous évolurez en autonomie.', 'De nombreux parcours sont disponibles du plus facile au plus sportif pour un contact progressif avec le vide.', 'Tyroliennes, ponts de singe, filets suspendus, plus de 50 ateliers différents pour toute la famille à partir de 4 ans.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Encadrement'],
			title2: 'Ouverture',
			subtitle2: ['Du 01/07 au 31/08 tous les jours sur réservation', 'Vacances scolaires sur réservation uniquement'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 12 €']
		},
		contacts: {
			phone: '07 52 03 66 48',
			photo: aloaImg,
			diplome: 'Educateur sportif BE escalade',
			name: 'Serge Laborie',
			email: 'aloanature@gmail.com',
			adress: ['Route du pont de mels', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Accrobranche',
			description: 'Prenez de la hauteur dans les arbres, dans un cadre sauvage et préservé ou vous évolurez en autonomie.'
		}
	},
	canyoning: {
		description: {
			title: 'Canyoning',
			text: ['Le canyon de l’Argence situé dans un site grandiose est devenu incontournable pour les amateurs de sensations fortes. Il est composé de 2 parties entrecoupées par un replat. La 1ère est idéale pour l’initiation et la 2ème est plus sportive. Il s’agit d’un canyon varié avec de nombreuses cascades de styles différents, qui permettent des sauts, toboggans et descentes en rappel.', 'D’autres canyons moins ludiques sont possibles sur le secteur.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Encadrement'],
			title2: 'Ouverture',
			subtitle2: ['De Juin à septembre en fonction des conditions météos'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 48€', 'Tarifs dégressifs']
		},
		contacts: {
			phone: '07 52 03 66 48',
			photo: aloaImg,
			diplome: 'Educateur sportif BE escalade',
			name: 'Serge Laborie',
			email: 'aloanature@gmail.com',
			adress: ['Route du pont de mels', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Canyoning',
			description: 'Le canyon de l’Argence situé dans un site grandiose est devenu incontournable pour les amateurs de sensations fortes.'
		}
	},
	'disc-golf': {
		description: {
			title: 'Le disc golf',
			text: ['Découvrez une nouvelle discipline, le disc-golf, sur un parcours naturel au plan d\'eau de la Vignotte à Sainte Geneviève sur Argence équipé de 12 corbeilles et d\'un practice.', 'Equipé de frisbees professionnels, vous partirez sur le parcours avec l’objectif de faire le moins de coups possibles pour atteindre les corbeilles. Organisation de tournois.', 'Stand de présentation au départ : plan, règlement, explications.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Libre accès'],
			title2: 'Ouverture',
			subtitle2: ['Du 1/07 au 31/08 location matériel', 'Toute l’année en libre accès', 'Location de matériel sur réservation'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 4€']
		},
		contacts: {
			phone: '07 88 23 17 95',
			email: 'servicedessports@argencesenaubrac.fr',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Disc-golf',
			description: 'Découvrez une nouvelle discipline, le disc-golf, sur un parcours naturel au plan d\'eau de la Vignotte à sainte Geneviève sur Argence.'
		}
	},
	'sport-deau': {
		description: {
			title: 'Les sports d\'eau',
			text: ['Au plan d’eau de Sainte Geneviève sur Argence ou sur le lac de Sarrans, venez profiter d’un moment de détente et de balade en kayak ou en stand up paddle.', 'Accessible à tous et en famille, des pédalos sont également disponibles.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Encadrement', 'Location de matériel'],
			title2: 'Ouverture',
			subtitle2: ['Du 01/07 au 31/08', 'Entre 13h10 à 19h'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 4€']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Sports d\'eau',
			description: 'Au plan d’eau de Sainte Geneviève sur Argence ou sur le lac de Sarrans, venez profiter d’un moment de détente et de balade en kayak ou en stand up paddle.'
		}
	},
	peche: {
		description: {
			title: 'La pêche',
			text: ['La pêche est votre passion ? Argences en Aubrac est votre destination !', 'Avec ses nombreuses rivières (l’Argence, le Réols…) et torrents de montagne, ses plans d’eau et son lac de Sarrans, le territoire de l’Argence est incontestablement le paradis des pêcheurs.', 'C’est avec un grand plaisir que nous vous accueillerons pour vous adonner à votre passion, soit avec un Guide de Pêche, soit seul. Pour compléter notre offre, 3 parcours no-kill sont accessibles sur le territoire.']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Encadrement'],
			title2: 'Ouverture',
			subtitle2: ['Dates d\'ouverture de la pêche'],
			title3: 'Tarifs',
			subtitle3: ['Tarif carte de pêche']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Pêche',
			description: 'La pêche est votre passion ? Argences en Aubrac est votre destination !'
		}
	},
	trampoline: {
		description: {
			title: 'Trampoline',
			text: ['Envie de prendre de la hauteur, venez découvrir une activité fun, ludique et accessible à tous. Sur réservation, séance de Trampoline à Lacalm']
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Activité encadrée par des moniteurs diplômés.', 'Location'],
			title2: 'Ouverture',
			subtitle2: ['Sur réservation Juillet Août'],
			title3: 'Tarifs',
			subtitle3: ['À partir de 5€']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Trampoline',
			description: 'Venez vivre une expérience à rebondissement !'
		}
	},
	'vtt-electrique': {
		description: {
			title: 'VTT à assistance électrique',
			text: ['Au plan d’eau de Sainte Geneviève, partez à la découverte du territoire avec nos VTT à Assistance Électrique. Parcourez l’Argence sans difficulté. Avec une grande autonomie, vous pouvez partir à la journée où à la demi-journée.', 'Découvrez également le tout nouveau parcours labélisé VTT FFC au départ de Graissac', 'Tracé GPS sur le lien suivant :'],
			link: 'https://www.openrunner.com/r/8114692'
		},
		informations: {
			title1: 'Prestations',
			subtitle1: ['Location'],
			title2: 'Ouverture',
			subtitle2: ['Toute l\'année sur réservation'],
			title3: 'Tarifs',
			subtitle3: ['Sur demande', 'Tarif de groupe', 'À la demi-journée', 'À la journée', 'À la semaine']
		},
		contacts: {
			phone: '07 88 23 17 95',
			photo: lilianImg,
			diplome: 'Educateur sportif',
			name: 'Lilian Fabre',
			email: 'servicedessports@argencesenaubrac.fr',
			adress: ['Service des sports de la commune d\'Argences en Aubrac', 'Mairie – Place des Tilleuls', 'Ste Geneviève sur Argence', '12420 Argences-en-Aubrac']
		},
		metaTags: {
			title: 'Trampoline',
			description: 'Parcourez les paysages d\'Aubrac'
		}
	},
};
