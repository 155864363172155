import Via1 from '../../../asset/img/via/via1.jpg';
import Via2 from '../../../asset/img/via/via2.jpg';
import Via4 from '../../../asset/img/via/via4.jpg';
import Via5 from '../../../asset/img/via/via5.jpg';
import Via6 from '../../../asset/img/via/via6.jpg';
import Via7 from '../../../asset/img/via/via7.png';

import cerfVolant1 from '../../../asset/img/cerf_volant/cerf1.jpg';
import cerfVolant2 from '../../../asset/img/cerf_volant/cerf2.jpg';
import cerfVolant3 from '../../../asset/img/cerf_volant/cerf3.jpg';

import Trotinette1 from '../../../asset/img/trott/trott1.jpg';
import Trotinette2 from '../../../asset/img/trott/trott2.JPG';
import Trotinette3 from '../../../asset/img/trott/trott3.JPG';
import Trotinette4 from '../../../asset/img/trott/trott4.jpg';

import Randonnee1 from '../../../asset/img/randonnee/randonnee1.jpg';
import Randonnee2 from '../../../asset/img/randonnee/randonnee2.JPG';
import Randonnee3 from '../../../asset/img/randonnee/randonnee3.JPG';
import Randonnee4 from '../../../asset/img/randonnee/background.jpg';

import TirALarc1 from '../../../asset/img/tir_a_larc/tir_a_larc1.JPG';
import TirALarc2 from '../../../asset/img/tir_a_larc/tir_a_larc2.JPG';
import TirALarc3 from '../../../asset/img/tir_a_larc/tir_a_larc3.JPG';
import TirALarc4 from '../../../asset/img/tir_a_larc/tir_a_larc4.JPG';

import Accrobranche1 from '../../../asset/img/accrobranche/accrobranche1.JPG';
import Accrobranche2 from '../../../asset/img/accrobranche/accrobranche2.JPG';
import Accrobranche3 from '../../../asset/img/accrobranche/accrobranche3.jpg';

import Canyoning1 from '../../../asset/img/canyoning/canyoning1.jpg';
import Canyoning2 from '../../../asset/img/canyoning/canyoning2.jpg';
import Canyoning3 from '../../../asset/img/canyoning/canyoning3.jpg';
import Canyoning4 from '../../../asset/img/canyoning/canyoning4.jpg';


import Vtt1 from '../../../asset/img/vtt/vtt1.jpeg';
import Vtt2 from '../../../asset/img/vtt/vtt2.jpeg';
import Vtt3 from '../../../asset/img/vtt/vtt3.jpeg';
import Vtt4 from '../../../asset/img/vtt/vtt4.jpeg';
import Vtt5 from '../../../asset/img/vtt/vtt5.jpeg';

import DiscGolf1 from '../../../asset/img/disc_golf/disc_golf1.JPG';
import DiscGolf2 from '../../../asset/img/disc_golf/disc_golf2.JPG';
import DiscGolf3 from '../../../asset/img/disc_golf/disc_golf3.JPG';
import DiscGolf4 from '../../../asset/img/disc_golf/disc_golf4.JPG';

import SportDeau1 from '../../../asset/img/sport_deau/sport_deau1.JPG';
import SportDeau2 from '../../../asset/img/sport_deau/sport_deau2.JPG';
import SportDeau3 from '../../../asset/img/sport_deau/sport_deau3.JPG';
import SportDeau4 from '../../../asset/img/sport_deau/sport_deau4.JPG';

import Peche1 from '../../../asset/img/peche/peche1.JPG';
import Peche2 from '../../../asset/img/peche/peche2.jpg';
import Peche3 from '../../../asset/img/peche/peche3.JPG';

import Trampoline1 from '../../../asset/img/trampoline/trampoline1.jpg';

import VttElectrique1 from '../../../asset/img/vttElectrique/vttElectrique1.jpg';


export const cerfVolant = [
  { src: `${cerfVolant1}`, width: 4, height: 3, alt: 'cerf-volant argences lilian' },
  { src: `${cerfVolant2}`, width: 1, height: 1, alt: 'cerf-volant argences soleil' },
  { src: `${cerfVolant3}`, width: 1, height: 1, alt: 'cerf-volant argences aubrac' }
];

export const via = [
  { src: `${Via1}`, width: 4, height: 3, alt: 'via ferrata argences aubrac traverse pont' },
  { src: `${Via2}`, width: 1, height: 1, alt: 'via ferrata argences aubrac pont' },
  { src: `${Via4}`, width: 1, height: 1, alt: 'via ferrata argences aubrac falaise' },
  { src: `${Via5}`, width: 2, height: 1, alt: 'via ferrata argences aubrac falaise photo drone' },
  { src: `${Via6}`, width: 1, height: 1, alt: 'via ferrata argences aubrac falaise groupe' },
  { src: `${Via7}`, width: 1, height: 1, alt: 'via ferrata plan' }
];

export const trottinette = [
  { src: `${Trotinette1}`, width: 3, height: 2, alt: 'trottinettes de descente groupe croix' },
  { src: `${Trotinette2}`, width: 3, height: 2, alt: 'trottinettes de descente groupe' },
  { src: `${Trotinette3}`, width: 3, height: 2, alt: 'trottinettes de descente' },
  { src: `${Trotinette4}`, width: 3, height: 2, alt: 'trottinettes de descente groupe' }
];

export const randonnee = [
  { src: `${Randonnee1}`, width: 3, height: 2, alt: 'argences aubrac randonnée champs' },
  { src: `${Randonnee2}`, width: 3, height: 2, alt: 'argences aubrac randonnée plateau' },
  { src: `${Randonnee3}`, width: 3, height: 2, alt: 'argences aubrac randonnée chemin' },
  { src: `${Randonnee4}`, width: 4, height: 2, alt: 'argences aubrac randonnée buron' }
];

export const tirALarc = [
  { src: `${TirALarc1}`, width: 3, height: 2, alt: 'tir à l\'arc argence aubrac tireur' },
  { src: `${TirALarc2}`, width: 3, height: 2, alt: 'tir à l\'arc argence aubrac cibles' },
  { src: `${TirALarc3}`, width: 3, height: 4, alt: 'tir à l\'arc argence aubrac cible' },
  { src: `${TirALarc4}`, width: 6, height: 4, alt: 'tir à l\'arc argence aubrac tireuse' }
];

export const accrobranche = [
  { src: `${Accrobranche1}`, width: 3, height: 2, alt: 'accrobranche argences aubrac obstacle' },
  { src: `${Accrobranche2}`, width: 3, height: 2, alt: 'accrobranche argences aubrac filet' },
  { src: `${Accrobranche3}`, width: 3, height: 2, alt: 'accrobranche argences aubrac garçon' }
];

export const canyoning = [
  { src: `${Canyoning1}`, width: 2, height: 3, alt: 'canyoning argences aubrac photo toboggan' },
  { src: `${Canyoning2}`, width: 3, height: 2, alt: 'canyoning argences aubrac photo saut' },
  { src: `${Canyoning3}`, width: 2, height: 3, alt: 'canyoning argences aubrac photo saut' },
  { src: `${Canyoning4}`, width: 3, height: 2, alt: 'canyoning argences aubrac photo saut' }
];

export const vtt = [
  { src: `${Vtt1}`, width: 3, height: 2, alt: 'vtt descente argences aubrac forêt feuilles' },
  { src: `${Vtt2}`, width: 3, height: 2, alt: 'vtt descente argences aubrac forêt saut' },
  { src: `${Vtt3}`, width: 3, height: 2, alt: 'vtt descente argences aubrac saut foret' },
  { src: `${Vtt4}`, width: 3, height: 2, alt: 'vtt descente argences aubrac saut jump' },
  { src: `${Vtt5}`, width: 3, height: 2, alt: 'vtt descente argences aubrac saut gap' }
];

export const discGolf = [
  { src: `${DiscGolf1}`, width: 3, height: 2, alt: 'disc-golf argences aubrac lac' },
  { src: `${DiscGolf2}`, width: 3, height: 2, alt: 'disc-golf argences aubrac' },
  { src: `${DiscGolf3}`, width: 3, height: 2, alt: 'disc-golf argences aubrac neige' },
  { src: `${DiscGolf4}`, width: 3, height: 2, alt: 'disc-golf argences aubrac neige gab' }
];

export const sportDeau = [
  { src: `${SportDeau1}`, width: 3, height: 2, alt: 'sport d\'eau argences aubrac paddle lac' },
  { src: `${SportDeau2}`, width: 3, height: 2, alt: 'sport d\'eau argences aubrac paddle' },
  { src: `${SportDeau3}`, width: 3, height: 2, alt: 'sport d\'eau argences aubrac pedalo lac' },
  { src: `${SportDeau4}`, width: 3, height: 2, alt: 'sport d\'eau argences aubrac pedalo lac' }
];

export const peche = [
  { src: `${Peche1}`, width: 5, height: 4, alt: 'pêche argences aubrac enfant' },
  { src: `${Peche2}`, width: 6, height: 4, alt: 'pêche argences aubrac ruisseau' },
  { src: `${Peche3}`, width: 6, height: 4, alt: 'pêche argences aubrac lac' }
];

export const trampoline = [
  { src: `${Trampoline1}`, width: 7, height: 5, alt: 'trampoline enfant aubrac argence' },
];

export const vttElectrique = [
  { src: `${VttElectrique1}`, width: 7, height: 5, alt: 'trampoline enfant aubrac argence' },
];



export const photosSwitch = (activity) => {
  switch (activity) {
    case 'cerf-volant':
      return cerfVolant
    case 'via':
      return via
    case 'trottinette':
      return trottinette
    case 'randonnee':
      return randonnee
    case 'tir-a-larc':
      return tirALarc
    case 'accrobranche':
      return accrobranche
    case 'canyoning':
      return canyoning
    case 'vtt':
      return vtt
    case 'disc-golf':
      return discGolf
    case 'sport-deau':
      return sportDeau
    case 'peche':
      return peche
    case 'trampoline':
      return trampoline
    case 'vtt-electrique':
      return vttElectrique
    default:
      return []
  }
}